import React from 'react';
import { CheckoutContextProvider, useCheckoutContext } from '../../../contexts/CheckoutContext';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import LoggedOut from '../../Checkout/LoggedOut';
import { useRetrieveQuotation } from '../../../hooks/useQuotation';
import Redeem from './Redeem';
import styles from './style.module.css';

export default function Retrieve(props) {
    return (
        <CheckoutContextProvider>
            <RetrieveRouter {...props} />
        </CheckoutContextProvider>
    );
}

function RetrieveRouter({quotationId, dpCode}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const { customer } = useCustomerContext();

    const { loading, error, data } = useRetrieveQuotation(quotationId, dpCode);
    const quotation = data?.retrieveQuotation;

    if (!checkout.redeemQuote) {
        setCheckout({...checkout, redeemQuote: true, quotationId: quotationId, dpCode: dpCode});
    }

    if (loading) {
        return(
            <div className={styles.retrieveQuote}>
                <h1>Retrieve a quote</h1>
                <p>Loading ...</p>
            </div>
        );
    }

    if (error) {
        return(
            <div className={styles.retrieveQuote}>
                <h1>Retrieve a quote</h1>
                <p>A valid quote was not found.</p>
            </div>
        );
    }

    if (quotation.customer_is_guest && !customer.token?.length) {
        return <LoggedOut />;
    }

    if (quotation && !checkout.shipping_schedule) {
        setCheckout({
            ...checkout, 
            shipping_schedule: {
                schedule: quotation.shipping_schedule
            },
            shipping_address: {
                shippingCountryId: quotation.shipping_country_id
            }
        });
    }

    console.log('CheckoutContext', checkout);

    return(
        <div className={styles.retrieveQuote}>
            <h1>Complete your purchase</h1>
            <Redeem quotation={quotation} />
        </div>
    );
}
